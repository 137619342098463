<template>
  <div class="FI-warp">
    <dl class="page-describe">
      <dt><img src="./../assets/king_ic.png" />中信银行 精选好基</dt>
      <dd @click="carefullyChosen">
        <p class="pd-item"><i></i>{{headerTxt[0]}}</p>
        <p class="pd-item">
          <i></i>{{headerTxt[1]}}<img
            src="./../assets/arr_ic.png"
          />
        </p>
      </dd>
    </dl>
    <ul class="characteristic">
      <li>
        <h3>业绩优秀</h3>
        <p>注重绝对收益</p>
        <p>短中长期排名前30%</p>
      </li>
      <li><i></i></li>
      <li>
        <h3>风控优秀</h3>
        <p>注重回撤幅度</p>
        <p>中期跌幅最小的前30%</p>
      </li>
      <li><i></i></li>
      <li>
        <h3>经验丰富</h3>
        <p>管理人</p>
        <p>至少经历1轮牛熊</p>
      </li>
    </ul>
    <!-- heade end -->

    <div class="card-box">
      <h2 class="m-tit">{{sectionList[0]?sectionList[0].name:''}}</h2>
      <p class="s-tit"><span class="go-detail" @click="goDetail(zoslC.fundCode,sectionList[0]?sectionList[0].name:'','https://wap.bank.ecitic.com/NMBFOServer/WebMB/MBWakeApp/wakeAppPage.html?VlWnQNU+zRe9Pt6V9StU3mMwfrxL0+IXHbEl5FcB4Jw7KtvHjtP549gOvpI8xru1gCtNSSI/OCyIP4p7WaBq5v7gAwRW5cLxCITICBANKLOGO')">详情<img src="./../assets/arr_ic02.png" /></span>{{sectionList[0]?sectionList[0].title:''}}</p>
      
      <div @click="buy(zoslC.fundCode,sectionList[0]?sectionList[0].name:'',zoslC.url)">
        <div class="fixed-income"><b>{{zoslC.fundName}}</b><span><img src="./../assets/dot_ic.png" />{{zoslC.title}}</span></div>
        <div class="tag-box mt15"><span class="tag01">{{zoslC.keepTag}}</span></div>
        <ul class="slide-data ovh">
          <li class="sd-value w40b">
            <p>{{zoslC.createValue}}</p>
            {{zoslC.createText}}
            <div><button class="buy-btn buyMin">立即购买</button></div>
          </li>
          <li class="w60b">
            <div class="barChart-box">
              <div id="myChart" :style="chartstyle"></div>
            </div>
          </li>
        </ul>
      </div>
      <!-- fixed income end -->
      
      <div class="line"></div>
      <h2 class="m-tit">{{sectionList[0]?sectionList[1].name:''}}</h2>
      <p class="s-tit">{{sectionList[0]?sectionList[1].title:''}}</p>
      <div style="position:relative">
      
      <div class="swiper-container">
        <div class="swiper-other" :style="{right:sRight+'px',opacity:sOpacity}">
          <p class="sop1"><img src="./../assets/arr_ic04.png" />滑动查看</p>
          <p class="sop2">季季享家族</p>
          <p class="sop2">3%～12%</p>
          <p class="sop4">近一年涨跌幅</p>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in slideList" :key="index">
            <div :class="'swiper-slide-item bg'+index%3" @click="buy(item.fundCode,sectionList[0]?sectionList[1].name:'',item.url)">
              <h3 class="slide-title">
                <span @click="goDetail(item.fundCode,sectionList[0]?sectionList[1].name:'',item.url)">详情<img src="./../assets/arr_ic02.png" /></span>{{item.fundName}}
              </h3>
              <div class="tag-box mt15">
                <span class="tag02" v-if="item.tag1">{{item.tag1}}</span
                ><span class="tag01 ml10" v-if="item.tag2">{{item.tag2}}</span>
              </div>
              <ul class="slide-data ovh">
                <li class="sd-value">
                  <p>{{item.lastYearValue}}</p>
                  近一年涨跌幅
                </li>
                <li>
                    
                  <div class="myChart-box">
                    <div class="slide-chart" :id="'myChartA'+index"></div>
                  </div>
                  <p class="bar-time">{{cardTime[0]?cardTime[index][0]:''}}<span>{{cardTime[0]?cardTime[index][1]:''}}</span></p>
                </li>
              </ul>
              <div class="medal-box">
                 <img v-if="item.title" src="./../assets/medal_ic.png" />{{item.title}}
              </div>
              <button class="buy-btn">立即购买</button>
            </div>
          </div>
          
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
      </div>
      <!-- Steady progress end -->

      <div class="line"></div>
      <h2 class="m-tit">{{sectionList[0]?sectionList[2].name:""}}</h2>
      <p class="s-tit">{{sectionList[0]?sectionList[2].title:""}}</p>
      <ul>
        <li class="master-item" v-for="(item,index) in masterList3" :key="index"  @click="goDetail(item.fundCode,sectionList[0]?sectionList[2].name:'',item.url)">
          <div class="master-chart-box">
            <div class="master-chart" :id="'myChartD'+index"></div>
          </div>
          <div class="master-data">
            <h3 class="master-title">{{item.title}} <span class="tag02" :class='{tag03:index==0}'>{{item.tag}}</span></h3>
            <div class="profit-time">
              <p><b :class="{negative:item.sinceEstablishmentValue && item.sinceEstablishmentValue.split('-').length>1,null:!item.sinceEstablishmentValue}">{{item.sinceEstablishmentValue?item.sinceEstablishmentValue:'― ―'}}</b>成立以来</p>
              <!-- <p><b :class="{negative:item.last6MonthValue.split('-').length>1,null:!item.last6MonthValue}">{{item.last6MonthValue?item.last6MonthValue:'― ―'}}</b>近半年</p> -->
              <p><b :class="{negative:item.val2 && item.val2.split('-').length>1,null:!item.val2}">{{item.val2?item.val2:'― ―'}}</b>{{item.t2}}</p>
              <p v-if="item.val3"><b :class="{negative:item.val3 &&item.val3.split('-').length>1,null:!item.val3}">{{item.val3?item.val3:'― ―'}}</b>{{item.t3}}</p>
              
            </div>
            <div class="master-item-describe">{{item.tip}}</div>
          </div>
        </li>
        
      </ul>
      <div class="master-more" v-if="moreHide">
        <p @click="getMore">点击查看更多<img src="./../assets/arr_ic03.png" /></p>
      </div>
      <!-- Master selection end -->
      
    </div>
    <!-- <div class="other-funds">
      没有心仪的产品？去看看其他
      <button class="axin-btn" @click="axin">去阿信货栈看看</button>
    </div> -->

  </div>
</template>

<script>
import Swiper, { Pagination, Navigation } from "swiper";
Swiper.use([Pagination, Navigation]);
import "swiper/swiper-bundle.css";

export default {
  name: "FixedIncome",
  props: {
    msg: String,
  },
   
  data() {
    return {
      headerTxt:[],
      sectionList:[],
      zoslC:{},
      slideList:[],
      masterListAll:[],
      masterList3:[],
      moreHide:true,
        
      lineData: [],
      lineAxis: [],
      sRight:-119,
      sOpacity:0,
      notJump:false,
      moveV:1,
      cardTime:[],
      chartstyle:{
        height: "190px",
        width: "380px"
      }, 
    };
  },
  computed: {
    
  },
  created() {
    
  },
  mounted() {
     
    let that = this;
    document.title = "十分精选";

    this.$axios({//顶部描述
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/tenpay-managing-money/getheader",
      method: "get",
      params: {},
    }).then((res) => {
      that.headerTxt = res.data.data;
    });

    this.$axios({//基金类型描述
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/tenpay-managing-money/getSectionList",
      method: "get",
      params: {},
    }).then((res) => {
      that.sectionList = res.data.data;
    });

    this.$axios({//中欧双利C
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/tenpay-managing-money/first",
      method: "get",
      params: {},
    }).then((res) => {
      that.zoslC = res.data.data;
      that.lineData = [];
      that.lineAxis = [];
      res.data.data.list.forEach((e) => {
          console.log(e)
          that.lineData.push(e.value.split("%")[0]);
          that.lineAxis.push(e.name);
      });
      
      that.chartBar();
    });

    this.$axios({//卡片基金列表
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/tenpay-managing-money/second",
      method: "get",
      params: {},
    }).then((res) => {
      that.slideList = res.data.data;
      setTimeout(() =>{
        that.swiperFn()
        that.cardTime = []
        that.slideList.forEach((item,index) => {
          console.log(item,index)
          let axisV = []
          let listV = []
          let listV2 = []
          let listVmin = 0
          
          that.cardTime.push([item.list[0].date.split('-')[0]+'/'+item.list[0].date.split('-')[1],item.list[item.list.length-1].date.split('-')[0]+'/'+item.list[item.list.length-1].date.split('-')[1]])
          item.list.forEach((item2) => {
            // cardTime.push([item.list[0].date.split('-')[0]+'/'+item.list[0].date.split('-')[1]])
            // if(index2 == 7){
            //   axisV.push(item.list[0].date.split('-')[0]+'/'+item.list[0].date.split('-')[1])
            // }else if(index2 == item.list.length -8){
            //   axisV.push(item.list[item.list.length-1].date.split('-')[0]+'/'+item.list[item.list.length-1].date.split('-')[1])
            // }else{
            //   axisV.push('')
            // }
            listV.push(item2.netValue)
            
          })
          listVmin = Math.min(...listV)
          listV.forEach(e => {
            listV2.push(e - listVmin)
          })
          this.chartLine('myChartA'+index).setOption({
              xAxis: {
                  data:axisV || ['','2019/06', '', '', '', '2020/06','' ]
              },
              series: [{
                  // 根据名字对应到相应的系列
                  name: '收益',
                  data: listV2,
              }]
          });
        })
        
      },200)
    });

    this.$axios({//大师精选基金列表
      url:
        "https://bs-activity-server.covoart.com/pomotion-api/tenpay-managing-money/third",
      method: "get",
      params: {},
    }).then((res) => {
      res.data.data.sort((a, b) => {
        return parseFloat(a.sinceEstablishmentValue) > parseFloat(b.sinceEstablishmentValue) ? -1 : 1;
      })




      that.masterList3 = res.data.data.splice(0,3)
      that.masterListAll = res.data.data;
      setTimeout(() =>{
        that.masterList3.forEach((item,index) => {
          console.log(item,index)
          let listV = []
          let listV2 = []
          let listVmin = 0
          item.list.forEach((item2) => {
            listV.push(item2.netValue)
          })
          listVmin = Math.min(...listV)
          listV.forEach(e => {
            listV2.push(e - listVmin)
          })
          this.chartLineMin('myChartD'+index).setOption({
              series: [{
                  // 根据名字对应到相应的系列
                  name: '销量',
                  data: listV2,
              }]
          });


          switch(item.fundCode){
            case '501022':
              item.val2 = item.lastYearValue;
              item.t2 = '近一年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '450004':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '001667':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '001445':
              item.val2 = item.lastYearValue;
              item.t2 = '近一年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '166019':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '006392':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '009402':
              item.val2 = item.lastYearValue;
              item.t2 = '近一年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '011251':
              item.val2 = item.last6MonthValue;
              item.t2 = '近半年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '010429':
              item.val2 = item.last6MonthValue;
              item.t2 = '近半年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '013812':
              item.val2 = item.lastMonthValue;
              item.t2 = '近一月';
              item.val3 = null;
              item.t3 = '';
            break;
            case '008145':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '006321':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;

            default:
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
          }

          return item;

        })
        this.$forceUpdate();

        
      },200)
    });
    

 
  },
  methods: {
    carefullyChosen(){//十分精选
      this.$uweb.trackEvent('十分精选', "查看详情", "", 0, "view");
      window.location.href = './allFunds.html';
    },
    axin(){//阿信客栈
      this.$uweb.trackEvent('阿信货栈', "查看详情", "", 0, "view");
      window.location.href = 'https://activity.cdollar.cn/p/q/bf5e15ec#/';
    },
    
    buy(code,category,url) {//立即购买
      this.$uweb.trackEvent(category, "购买" + code, "", 0, "view");
      window.location.href = url;
    },
    goDetail(code,category,url) {//查看详情
      this.$uweb.trackEvent(category, "查看详情" + code, "", 0, "view");
      window.location.href = url;
    },
    getMore(){//大师精选 查看更多
      this.$uweb.trackEvent('大师精选', "查看更多", "", 0, "view");
      this.moreHide = false;
      this.masterList3 = this.masterList3.concat(this.masterListAll),
      setTimeout(() =>{
        console.log(this.msms)
        this.masterList3.forEach((item,index) => {
          console.log(item,index)
          if(index >= (this.masterList3.length-this.masterListAll.length)){
            let listV = []
            let listV2 = []
            let listVmin = 0
            item.list.forEach((item2) => {
              listV.push(item2.netValue)
            })
            listVmin = Math.min(...listV)
            listV.forEach(e => {
              listV2.push(e - listVmin)
            })
            this.chartLineMin('myChartD'+index).setOption({
                series: [{
                    // 根据名字对应到相应的系列
                    name: '销量',
                    data: listV2,
                }]
            });
            
          }



          switch(item.fundCode){
            case '501022':
              item.val2 = item.lastYearValue;
              item.t2 = '近一年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '450004':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '001667':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '001445':
              item.val2 = item.lastYearValue;
              item.t2 = '近一年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '166019':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '006392':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '009402':
              item.val2 = item.lastYearValue;
              item.t2 = '近一年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '011251':
              item.val2 = item.last6MonthValue;
              item.t2 = '近半年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '010429':
              item.val2 = item.last6MonthValue;
              item.t2 = '近半年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '013812':
              item.val2 = item.lastMonthValue;
              item.t2 = '近一月';
              item.val3 = null;
              item.t3 = '';
            break;
            case '008145':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
            case '006321':
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;

            default:
              item.val2 = item.last3YearValue;
              item.t2 = '近三年';
              item.val3 = null;
              item.t3 = '';
            break;
          }

          return item;

        })
        this.$forceUpdate();
      },200)
    },
    swiperFn(){//初始化轮播图
      let that = this
      var startX,endX;
      new Swiper(".swiper-container", {
        pagination: {
          el: ".swiper-pagination",
        },
        watchSlidesProgress: true, //查看slide的progress
        // resistanceRatio: 0, //禁止边缘移动
        loop: false,
        on: {
          init: function () {
            let slides = this.slides;
            for (let i = 0; i < slides.length; i++) {
              let slide = slides.eq(i);
              slide.css("zIndex", 100 - i); //设置slide的z-index层级
            }
          },
          resize: function (swiper) {
            swiper.update();
          },
          slideNextTransitionEnd: function(){
            if(this.activeIndex == 3){
              that.sOpacity = 1
              that.moveV = 1
            }else{
              that.sOpacity = 0
            }
          },
          
          slideChangeTransitionStart: function(){
            that.sOpacity = 0
          },
          touchStart: function(swiper,event){
            var touch = event.touches[0]
            startX = touch.pageX;

    
          },
          sliderMove: function(swiper,event){
            var touch = event.touches[0]
            endX = touch.pageX - startX;
              if(this.activeIndex == 3){
                console.log(endX)
                that.moveV ++
                if(endX < 0 && that.sRight<-30){
                  that.sRight = -119+that.moveV*5
                }
                
              }
          },
          touchEnd: function(){
            if(this.activeIndex == 3 && that.sRight >= -30 && endX < 0){
                that.$uweb.trackEvent('季季享家族', "查看详情", "", 0, "view");
                window.location.href = 'https://activity.cdollar.cn/p/q/cae8a38c';
            }
            that.sRight = -119
            that.moveV = 1
            
          },
          
          setTranslate: function () {
            let slides = this.slides;
            let offsetAfter = this.width * 0.94; //每个slide的位移值
            for (let i = 0; i < slides.length; i++) {
              let slide = slides.eq(i);
              let progress = slides[i].progress;

              if (progress <= 0) {
                //右边slide位移
                slide.transform(
                  "translate3d(" +
                    progress * offsetAfter +
                    "px, 0, 0) scale(" +
                    (1 - Math.abs(progress) / 20) +
                    ")"
                );
                slide.css("opacity", progress + 3); //最右边slide透明
              }

              if (progress > 0) {
                // slide.transform('rotate('+ (-progress)*5+'deg)');   //左边slide旋转
                slide.css("opacity", 1 - progress); //左边slide透明
              }
            }
          },
          setTransition: function (swiper, transition) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
        },
      });
    },
    chartLine(id) {//轮播图里的折线图
      let myChart = this.$echarts.init(document.getElementById(id));
      myChart.setOption({
        tooltip: {},
        xAxis: {
          data: [],
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {//x轴线的颜色以及宽度
                show: true,
                lineStyle: {
                    color: "#ececec",
                    width: 2,
                    type: "solid"
                }
          },
          axisLabel: {
            interval: 0,
            fontSize: 17,
            fontFamily: "Microsoft YaHei",
            color: "#999",
          },
        },
        yAxis: {
          show:false,
        },
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 30,
        },
        series: [
          {
            name: "销量",
            type: "line",
            smooth: true,
            symbol:'none',
            areaStyle:{
              color:{
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#feeaec' // 0% 处的颜色
                }, {
                    offset: 1, color: '#ffffff' // 100% 处的颜色
                }],
              }
            },
            itemStyle: {
                normal: {
                    color: '#c05030',
                    lineStyle:{
                        width:4//设置线条粗细
                    }
                }
            },
            data: [],
          },
        ],
      });
      return myChart;
    },
    chartLineMin(id) {//大师精选里的折线图
      let myChart = this.$echarts.init(document.getElementById(id));
      myChart.setOption({
        tooltip: {},
        xAxis: {
          data: [],
          boundaryGap: false,
          show : false,
          
        },
        yAxis: {
          show:false,
        },
        grid: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 0,
        },
        series: [
          {
            name: "销量",
            type: "line",
            smooth: true,
            symbol:'none',
            areaStyle:{
              color:{
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#feeaec' // 0% 处的颜色
                }, {
                    offset: 1, color: '#ffffff' // 100% 处的颜色
                }],
              }
            },
            itemStyle: {
                normal: {
                    color: '#ff5046',
                    lineStyle:{
                        width:2//设置线条粗细
                    }
                }
            },
            data: [],
          },
        ],
      });
      return myChart;
    },
     
    chartBar() {//安稳固收柱状图
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      let that = this;
      myChart.setOption({
        // title: { text: "在Vue中使用echarts" },
        tooltip: {},
        xAxis: {
          data: that.lineAxis,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#f3f3f3", // 颜色
            },
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Microsoft YaHei",
            color: "#999",
          },
        },
        yAxis: {
          type : 'value',
          splitNumber:3,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            fontFamily: "Microsoft YaHei",
            color: "#999",
            formatter:'{value}.00%'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#e7e7e7"],
            },
          },
        },
        grid: {
          x: 50,
          y: 40,
          x2: 0,
          y2: 30,
        },
        title: {
            text: '季度收益图',
            textStyle:{
              color:'#999',
              fontSize :'14'
            }
        },
        series: [
          {
            name: "收益",
            type: "bar",
            barWidth: 21, //柱图宽度
            data: that.lineData,
            itemStyle: {
              normal: {
                color: function (item) {
                  if (item.data > 0) {
                    return "#c05030";
                  } else {
                    return "#addb92";
                  }
                },
              },
            },
             
          },
        ],
      });

      let w = document.body.clientWidth;
      console.log(w);
      let sc = w / 750;
      console.log(sc);
      let chartstyle = {
        height: (300 * w) / 750 + "px",
        transform: "scale(" + sc + ")",
        "transform-origin": "left top"
      };
      this.chartstyle = chartstyle;


    },
     
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// @import url('./FixedIncome.scss');
@import "./FixedIncome.scss";
</style>
<style lang="scss">
.swiper-pagination{
  &.swiper-pagination-bullets{bottom: 0;}
}
.swiper-container{
  .slide-data{height: 130px;}
}
</style>
