import { render, staticRenderFns } from "./App.vue?vue&type=template&id=ea06edec&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!G:\\work\\2020GIT\\bs-zx-wjlc\\node_modules\\_vue-loader@15.9.6@vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports